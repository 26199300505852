import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { HeaderMenuHelperService, SearchHelperService } from '@offconon/shared/utils/services';

import { GeneralSearchModuleComponent } from '../main/general-search-module.component';

@Component({
  selector: 'offconon-layout-search',
  standalone: true,
  imports: [TranslateModule, ButtonModule, GeneralSearchModuleComponent],
  templateUrl: './layout-search.component.html',
})
export class LayoutSearchComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private headerMenuHelperService = inject(HeaderMenuHelperService);
  private searchService = inject(SearchHelperService);
  private router = inject(Router);

  topSearch: any;
  search_title: any;

  general_search = true;
  module_data: any;

  general_search_parameters: any = {};

  ngOnInit() {
    this.general_search_parameters = {
      header_main_search: true,
    };

    this.headerMenuHelperService.headerModuleSearch$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.search_title = data.profile_type;
            this.module_data = data;
            this.general_search = false;
          } else {
            this.module_data = null;
            this.general_search = true;
          }
        },
      });

    this.searchService.searchPanel$.subscribe({
      next: (state: any) => {
        this.topSearch = state;
      },
    });

    this.searchService.searchPanel$.subscribe({
      next: (state: any) => {
        this.topSearch = state;
      },
    });

    this.searchService.searchPanelKeyword$.subscribe({
      next: (keyword: any) => {
        this.search_title = keyword;
      },
    });
  }

  buttonClicked(link: any) {
    this.topSearch = false;
    this.router.navigate([link]);
  }

  close() {
    this.topSearch = false;
    this.search_title = null;
  }

  clickSearchButton() {
    if (!this.general_search && this.module_data) {
      this.openModuleSearch();
    } else {
      this.topSearch = true;
      this.searchService.setopenFilterSearch(false);
    }
  }

  openModuleSearch() {
    this.searchService.setopenFilterSearch();
  }
}
