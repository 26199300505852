@if (data.visible) {
  <li class="mb-3 flex align-items-start p-3">
    @if (data.target) {
      <a class="nav-link-style text-white w-full" target="{{ data.target }}" href="{{ data.link }}">
        <div class="flex flex-row">
          <mat-icon class="w-3rem">{{ data.icon }}</mat-icon>

          <div class="flex flex-column nav-element-container">
            <span>
              {{ data.firstLine | translate }}
            </span>
            <span
              class="mt-2 mb-0 line-height-3 text-indigo-200 nav-link-style text-xs font-italic">
              {{ data.secondLine | translate }}
            </span>
          </div>
        </div>
      </a>
    } @else {
      <a
        class="nav-link-style text-white w-full"
        [routerLink]="[data.link]"
        (click)="linkClicked()">
        <div class="flex flex-row">
          <mat-icon class="w-3rem">{{ data.icon }}</mat-icon>

          <div class="flex flex-column nav-element-container">
            <span>
              {{ data.firstLine | translate }}
            </span>
            <span
              class="mt-2 mb-0 line-height-3 text-indigo-200 nav-link-style text-xs font-italic">
              {{ data.secondLine | translate }}
            </span>
          </div>
        </div>
      </a>
    }
  </li>
}
