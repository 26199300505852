<offconon-layout [sideNavData]="sideNav">
  <ng-container center>
    <offconon-layout-search />
    <offconon-shared-ui-theme-switch *ngIf="!isMobileView"></offconon-shared-ui-theme-switch>

    <offconon-language-change-only-flag *ngIf="!isMobileView"></offconon-language-change-only-flag>
  </ng-container>
  <div rightSide></div>
</offconon-layout>
<p-toast position="top-right"></p-toast>
