import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import locales from 'libs/shared/features/layout/layout/src/assets/locales.json';
import { UrlService } from 'libs/shared/utils/services/src/lib/url.service';
import { variables } from 'libs/shared/utils/variables/variables';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';

import { ThemeService } from '@offconon/shared/ui/theme-switch';

import { LanguageHelperService } from '../../service/language-helper.service';

@Component({
  selector: 'offconon-language-change-only-flag',
  templateUrl: './language-change-only-flag.component.html',
  standalone: true,
  imports: [
    TooltipModule,
    SidebarModule,
    ButtonModule,
    DividerModule,
    TranslateModule,
    CommonModule,
  ],
})
export class LanguageChangeOnlyFlagComponent implements OnInit {
  @ViewChild('langChangeSidebarRef') private langChangeSidebarRef: Sidebar;
  langChangeSidebar = false;
  usedLang: string;
  private translate = inject(TranslateService);
  private urlService = inject(UrlService);
  private themeService = inject(ThemeService);
  private router = inject(Router);

  public languageHelperService = inject(LanguageHelperService);

  langsrc = this.translate.getLangs();
  rtlView: boolean = false;
  public lang = new FormControl('');

  constructor() {
    // this.translate.setDefaultLang(variables.defaultLanguage);
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      if (this.themeService.rtlViewLang.indexOf(event.lang) >= 0) this.rtlView = true;
      else this.rtlView = false;
    });

    if (localStorage.getItem(variables.languageCodeStorageKey)) {
      this.usedLang = localStorage.getItem(variables.languageCodeStorageKey) as string;
    } else {
      this.usedLang = this.translate.getBrowserLang() || variables.defaultLanguage;
    }
    this.translate.use(this.usedLang);
  }

  onLangChange(lang: string) {
    if (lang == '') return;
    this.urlService.setLanguageStore(lang);
    this.usedLang = lang;
    this.langChangeSidebar = false;
    this.translate.use(lang);

    const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
    let currentUrlPath = this.router.url.slice(1).split(urlDelimitators);
    let url = '';
    if (locales.locales.includes(currentUrlPath[0])) {
      url = this.router.url.replace(currentUrlPath[0], lang);
    } else {
      url = `/${lang}/${currentUrlPath.join('/')}`;
    }
    window.location.href = url;
  }

  hideLangChangeSidebar() {
    this.langChangeSidebar = false;
    this.langChangeSidebarRef.hide();
  }
}
