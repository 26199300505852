import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { EmployeeRuleByCoWorkerRule } from '@offconon/shared/features/generated-api';

import * as WorkroomFeatureRbacRuleActions from './workroom-feature-rbac-rule.actions';
import { WorkroomFeatureRbacRuleEntity } from './workroom-feature-rbac-rule.models';

export const WORKROOM_FEATURE_RBAC_RULE_FEATURE_KEY = 'workroomFeatureRbacRule';

export interface WorkroomFeatureRbacRuleState extends EntityState<WorkroomFeatureRbacRuleEntity> {
  error?: string | null; // last known error (if any)
  rbacRuleList: EmployeeRuleByCoWorkerRule[];
}

export interface WorkroomFeatureRbacRulePartialState {
  readonly [WORKROOM_FEATURE_RBAC_RULE_FEATURE_KEY]: WorkroomFeatureRbacRuleState;
}

export const workroomFeatureRbacRuleAdapter: EntityAdapter<WorkroomFeatureRbacRuleEntity> =
  createEntityAdapter<WorkroomFeatureRbacRuleEntity>();

export const initialWorkroomFeatureRbacRuleState: WorkroomFeatureRbacRuleState =
  workroomFeatureRbacRuleAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    rbacRuleList: [],
  });

const reducer = createReducer(
  initialWorkroomFeatureRbacRuleState,
  on(WorkroomFeatureRbacRuleActions.initWorkroomFeatureRbacRule, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    WorkroomFeatureRbacRuleActions.loadWorkroomFeatureRbacRuleSuccess,
    (state, { workroomFeatureRbacRule }) =>
      workroomFeatureRbacRuleAdapter.setAll(workroomFeatureRbacRule, { ...state, loaded: true }),
  ),
  on(WorkroomFeatureRbacRuleActions.loadWorkroomFeatureRbacRuleFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    WorkroomFeatureRbacRuleActions.fetchCoworkerRbacRuleListSuccess,
    (state, { rbacRuleList }) => ({
      ...state,
      rbacRuleList,
    }),
  ),
);

export function workroomFeatureRbacRuleReducer(
  state: WorkroomFeatureRbacRuleState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
