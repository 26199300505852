import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CustomFieldTemplateEffects } from 'libs/shared/ui/custom-field-select-editor-render/src/lib/store/custom-field-template.effects';
import { UrlGuard } from 'libs/shared/utils/guards/url.guard';

import { LoginActiveGuard } from '@offconon/admin/features/auth';
import {
  CATEGORY_TEMPLATE_ADMIN_FEATURE_KEY,
  CategoryTemplateAdminEffects,
  categoryTemplateAdminReducer,
} from '@offconon/admin/features/category-template-admin';
import {
  SEARCH_FEATURE_KEY,
  SearchEffects,
  searchReducer,
} from '@offconon/shared/data/stores/search';
import {
  CUSTOM_FIELD_TEMPLATE_FEATURE_KEY,
  customFieldTemplateReducer,
} from '@offconon/shared/ui/custom-field-select-editor-render';

export const appRoutes: Routes = [
  {
    path: ':lang',
    canActivate: [UrlGuard],
    children: [
      {
        path: '',
        canActivate: [LoginActiveGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@offconon/workroom/features/main').then((m) => m.WorkroomFeaturesMainRoutes),
          },

          {
            path: 'rbac-admin',
            loadChildren: () =>
              import('@offconon/admin/features/rbac-admin-module/rbac-admin-access-options').then(
                (m) => m.rbacAdminAccessOptionsRoutes,
              ),
          },

          {
            path: 'applications-admin',
            loadChildren: () =>
              import('@offconon/admin/features/applications-admin').then(
                (m) => m.workroomFeaturesApplicationAdminRoutes,
              ),
            providers: [
              importProvidersFrom(
                StoreModule.forFeature(SEARCH_FEATURE_KEY, searchReducer),
                EffectsModule.forFeature(SearchEffects),
              ),
            ],
          },

          {
            path: 'currency-exchange-rate',
            loadChildren: () =>
              import(
                '@offconon/admin/features/currency-exchange-module/currency-exchange-routing'
              ).then((m) => m.currencyExchangeRoutingRoutes),
          },

          {
            path: 'subscription-prices-admin',
            loadChildren: () =>
              import(
                '@offconon/admin/features/subscription-module/subscription-price-admin-routing'
              ).then((m) => m.subscriptionPriceAdminRoutingRoutes),
          },

          {
            path: 'tax-admin',
            loadChildren: () =>
              import('@offconon/admin/features/tax-admin').then((m) => m.taxAdminRoutes),
          },

          {
            path: 'keyword-admin',
            loadChildren: () =>
              import('@offconon/admin/features/keyword-admin').then(
                (m) => m.AdminFeaturesKeywordAdminRoutes,
              ),
          },

          {
            path: 'academy-admin',
            loadChildren: () =>
              import('@offconon/admin/features/academy-module/academy-module-admin-routing').then(
                (m) => m.academyModuleAdminRoutingRoutes,
              ),
          },

          {
            path: 'category-template-admin',
            loadChildren: () =>
              import('@offconon/admin/features/category-template-admin').then(
                (m) => m.AdminFeaturesCategoryTemplateAdminRoutes,
              ),
            providers: [
              importProvidersFrom(
                StoreModule.forFeature(SEARCH_FEATURE_KEY, searchReducer),
                EffectsModule.forFeature(SearchEffects),

                StoreModule.forFeature(
                  CATEGORY_TEMPLATE_ADMIN_FEATURE_KEY,
                  categoryTemplateAdminReducer,
                ),
                EffectsModule.forFeature(CategoryTemplateAdminEffects),
              ),
            ],
          },

          {
            path: 'mr-ai-code-admin',
            loadChildren: () =>
              import('@offconon/admin/features/mr-ai-code-admin').then(
                (m) => m.AdminFeaturesMrAiCodeAdminRoutes,
              ),
          },

          {
            path: 'legal-entity-types-admin',
            loadChildren: () =>
              import('@offconon/admin/features/legal-entity-types-admin').then(
                (m) => m.AdminFeaturesLegalEntityTypesAdminRoutes,
              ),
          },

          {
            path: 'bank-database-management',
            loadChildren: () =>
              import(
                '@offconon/admin/features/financial-management-module/bank-module/admin-bank-routing'
              ).then((m) => m.adminBankRoutingRoutes),
          },
          {
            path: 'payment-providers-management',
            loadChildren: () =>
              import(
                '@offconon/admin/features/financial-management-module/payment-provider/payment-provider-admin-routing'
              ).then((m) => m.paymentProviderAdminRoutingRoutes),
          },

          {
            path: 'payment-on-spot-management',
            loadChildren: () =>
              import(
                '@offconon/admin/features/financial-management-module/payment-on-spot/payment-on-spot-admin-routing'
              ).then((m) => m.paymentOnSpotAdminRoutingRoutes),
          },

          {
            path: 'locations',
            loadChildren: () =>
              import('@offconon/admin/features/locations').then(
                (m) => m.AdminFeaturesLocationsRoutes,
              ),
          },

          {
            path: 'bucket-list-categories-admin',
            loadChildren: () =>
              import('@offconon/admin/features/bucket-list-categories-admin').then(
                (m) => m.AdminFeaturesBucketListCategoriesAdminRoutes,
              ),
          },

          {
            path: 'custom-fields',
            loadChildren: () =>
              import('@offconon/admin/features/custom-fields-admin').then(
                (m) => m.AdminFeaturesCustomFieldsAdminRoutes,
              ),
            providers: [
              importProvidersFrom(
                StoreModule.forFeature(
                  CUSTOM_FIELD_TEMPLATE_FEATURE_KEY,
                  customFieldTemplateReducer,
                ),
                EffectsModule.forFeature([CustomFieldTemplateEffects]),
              ),
            ],
          },

          {
            path: 'crm-targeted-customers',
            loadChildren: () =>
              import('@offconon/admin/features/crm-targeted-customers').then(
                (m) => m.AdminFeaturesCrmTargetedCustomersRoutes,
              ),
            providers: [
              importProvidersFrom(
                StoreModule.forFeature(SEARCH_FEATURE_KEY, searchReducer),
                EffectsModule.forFeature(SearchEffects),
              ),
            ],
          },

          {
            path: 'plant-catalog-admin',
            loadChildren: () =>
              import('@offconon/admin/features/plant-catalog-admin').then(
                (m) => m.adminFeaturesPlantCatalogRoutes,
              ),
            providers: [
              importProvidersFrom(
                StoreModule.forFeature(SEARCH_FEATURE_KEY, searchReducer),
                EffectsModule.forFeature(SearchEffects),
              ),
            ],
          },

          {
            path: 'food-drink-catalog-admin',
            loadChildren: () =>
              import(
                '@offconon/admin/features/catalog-module/food-drink/food-drink-catalog-admin-routing'
              ).then((m) => m.foodDrinkCatalogAdminRoutingRoutes),
          },

          {
            path: 'physical-product-catalog-admin',
            loadChildren: () =>
              import('@offconon/admin/features/physical-product-catalog-admin').then(
                (m) => m.physicalProductCatalogAdminRoutes,
              ),
          },

          {
            path: 'animal-catalog-admin',
            loadChildren: () =>
              import('@offconon/admin/features/animal-catalog-admin').then(
                (m) => m.AnimalCatalogAdminRoutes,
              ),
          },

          {
            path: 'si-units-admin',
            loadChildren: () =>
              import('@offconon/admin/features/si-units-admin').then(
                (m) => m.AdminFeaturesSiUnitsAdminRoutes,
              ),
          },

          {
            path: 'languages-admin',
            loadChildren: () =>
              import('@offconon/admin/features/languages-admin').then(
                (m) => m.AdminFeaturesLanguagesAdminRoutes,
              ),
          },

          {
            path: 'timezone-admin',
            loadChildren: () =>
              import('@offconon/admin/features/timezone-admin').then(
                (m) => m.AdminFeaturesTimezoneAdminRoutes,
              ),
          },
          {
            path: 'website-preview',
            loadChildren: () =>
              import('@offconon/admin/features/website-preview').then(
                (m) => m.AdminFeaturesWebsitePreviewRoutes,
              ),
          },

          {
            path: 'system-email-templates-admin',
            loadChildren: () =>
              import('@offconon/admin/features/system-email-templates-admin').then(
                (m) => m.SystemEmailTemplatesAdminRoutes,
              ),
          },
        ],
      },

      {
        path: 'login',
        loadComponent: () => import('@offconon/admin/features/auth').then((m) => m.LoginComponent),
      },
    ],
  },
];
