@if (loading) {
  <offconon-loading-loader-triangle />
} @else {
  <div id="offconon-layout" class="min-h-screen flex surface-section offconon-layout">
    @if (isLoggedIn) {
      <offconon-left-sidebar
        [sideNavData]="sideNavData"
        [sideBarVisible]="sideBarVisible"
        (sideBarVisibleChange)="sideBarVisible = $event" />
    }
    <div class="min-h-screen flex flex-column relative flex-auto">
      <div
        #cardContainer
        class="header-back-color flex card-container overflow-hidden border-bottom-1 border-300 h-4rem navbar"
        [@slideInAndOutAnimation]="isNavbarHidden">
        @if (isLoggedIn) {
          <div class="flex-none flex align-items-center justify-content-center">
            <a
              data-test-id="left-menu-button"
              style="margin-left: 1rem"
              class="cursor-pointer block text-700 mr-3"
              (click)="sideBarVisible = true">
              <i class="pi pi-bars text-4xl"></i>
            </a>
          </div>
        }
        <div class="flex-grow-1 flex align-items-center justify-content-center">
          <ng-content select="[center]" />
        </div>
        <div class="flex-none flex align-items-center justify-content-center">
          <ng-content select="[rightSide]" />
        </div>
      </div>

      <div class="p-1 flex mt-7 flex-column flex-auto" id="offconon-layout-content">
        <div class="z-0 min-h-screen">
          <router-outlet />
        </div>
      </div>
      <ng-content select="[footer]" />
    </div>
  </div>
}
@defer (on idle) {
  <offconon-fcm />
}
