import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { variables } from 'libs/shared/utils/variables/variables';
import { from, Observable } from 'rxjs';

class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [],
  imports: [
    TranslateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
      },
    }),
  ],
  providers: [TranslateService],
})
export class StorybookI18nModule {
  constructor(translateService: TranslateService) {
    translateService.setDefaultLang(variables.defaultLanguage);
    translateService.use(variables.defaultLanguage);
  }
}
