import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ThemeService } from '@offconon/shared/ui/theme-switch';

import { NavElement } from '../models/NavElement.model';

@Component({
  selector: 'offconon-nav-element',
  standalone: true,
  imports: [MatIcon, TranslateModule, RouterModule],
  templateUrl: './nav-element.component.html',
  styles: [
    `
      .nav-link-style {
        text-decoration: none;
      }

      .nav-element-container {
        width: -webkit-fill-available;
      }
    `,
  ],
})
export class NavElementComponent {
  @Input() public data: NavElement;
  @Output() linkClickedEvent = new EventEmitter<any>();
  isMobileView = false;
  constructor(private themeService: ThemeService) {
    this.themeService.mobileView.subscribe((isMobileView) => {
      this.isMobileView = isMobileView;
    });
  }

  public linkClicked() {
    this.linkClickedEvent.emit();
  }
}
