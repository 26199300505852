import { createAction, props } from '@ngrx/store';

import { WorkroomFeatureRbacRuleEntity } from './workroom-feature-rbac-rule.models';

export const initWorkroomFeatureRbacRule = createAction('[WorkroomFeatureRbacRule Page] Init');

export const loadWorkroomFeatureRbacRuleSuccess = createAction(
  '[WorkroomFeatureRbacRule/API] Load WorkroomFeatureRbacRule Success',
  props<{ workroomFeatureRbacRule: WorkroomFeatureRbacRuleEntity[] }>(),
);

export const loadWorkroomFeatureRbacRuleFailure = createAction(
  '[WorkroomFeatureRbacRule/API] Load WorkroomFeatureRbacRule Failure',
  props<{ error: any }>(),
);

export const fetchCoworkerRbacRuleList = createAction(
  '[WorkroomFeatureRbacRule/API] Fetch CoworkerRbacRule List',
);

export const fetchCoworkerRbacRuleListSuccess = createAction(
  '[WorkroomFeatureRbacRule/API] Fetch CoworkerRbacRule List Success',
  props<{ rbacRuleList: any }>(),
);

export const fetchCoworkerRbacRuleListFailure = createAction(
  '[WorkroomFeatureRbacRule/API] Fetch CoworkerRbacRule List Failure',
  props<{ error: any }>(),
);
