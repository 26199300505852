import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import locales from 'libs/shared/features/layout/layout/src/assets/locales.json';
import { variables } from 'libs/shared/utils/variables/variables';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  platformId = inject(PLATFORM_ID);

  shareUrl: string = variables.shareUrl;
  public translate = inject(TranslateService);

  constructor() {}

  /**
   *
   * @example this.urlService.generateShareUrl('profile/test', { test: 'test', test2: 'te2st' })
   * @param url test
   * @param params { test: 'test', test2: 'te2st' }
   * @returns  share/dGVzdD90ZXN0PXRlc3QmdGVzdDI9dGVzdDI=
   */
  public generateShareUrl(url: string, params: any): string {
    let result = `${url}?`;
    if (params) {
      Object.keys(params).forEach((key) => {
        result += `${key}=${key}&`;
      });
    }
    result = btoa(encodeURIComponent(result));

    return `${this.shareUrl}/${result}`;
  }

  /**
   *
   * @param url share/dGVzdD90ZXN0PXRlc3QmdGVzdDI9dGVzdDI=
   * @returns  test?test=test&test2=test2
   */
  public getSharedUrl(url: string): any {
    url = decodeURIComponent(url).split(`${this.shareUrl}/`)[1];
    url = atob(url);

    return url;
  }

  hasLanguageCode(url: string): boolean {
    const regexPattern = new RegExp(`^/(${locales.locales.join('|')})`);
    return regexPattern.test(url);
  }

  getLanguageCode(url: string) {
    const regexPattern = new RegExp(`^/(${locales.locales.join('|')})`);
    const match = url.match(regexPattern);
    return match ? match[1] : null; // Returns the matched language code or null
  }

  setLanguageStore(code: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(variables.languageCodeStorageKey, code ?? variables.defaultLanguage);
      this.translate.use(code ?? variables.defaultLanguage);
    }
  }
}
