<offconon-shared-ui-search-box [parameters]="parameters">
  <div class="grid searchdesign" [formGroup]="form">
    <div class="flex w-full align-items-center justify-content-between px-1 mt-2 pt-2">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-search text-white-alpha-80"></i>
        <input
          #key
          formControlName="translations"
          type="text"
          pInputText
          class="w-full border-none bg-transparent shadow-none outline-none text-white-alpha-80 text-sm"
          placeholder="{{ 'Type something to start the search' | translate }}" />
      </span>
      @if (this.form.value['translations']) {
        <span
          class="cursor-pointer p-1 border-400-alpha-20 border-1 border-round mr-2 text-white-alpha-80 text-xs bg-white-alpha-2s"
          (click)="resetForm()">
          <mat-icon class="text-red-300">delete</mat-icon></span
        >
      }
    </div>
    <p-divider class="w-full" />
    @if (form.value['translations'] && recent_search_parameters?.keywords) {
      <div class="col-12">
        <h2 class="font-semibold text-xs text-blue-200 mb-2 mt-0 px-1">
          {{ 'Recent searches' | translate }}
        </h2>

        <ul class="list-none m-0 p-0">
          @for (recent_keyword of recent_search_parameters?.keywords; track recent_keyword.id) {
            <li
              class="select-none p-2 text-white-alpha-80 font-light font-italic line-height-3 text-sm cursor-pointer border-round-lg hover:bg-white-alpha-20 hover:text-white"
              (click)="recentkeyword(recent_keyword?.label)">
              {{ recent_keyword?.label }}
            </li>
          }
        </ul>
      </div>
    }
    <div class="col-12">
      <p-accordion class="w-full" [style]="{ width: '100%' }" [multiple]="true">
        <p-accordionTab [selected]="!isMobileView" [tabStyleClass]="' mb-2'">
          <ng-template pTemplate="header">
            <span class="flex align-items-center gap-2 w-full text-blue-600">
              <i class="pi pi-align-left mr-1"></i>
              <span class="font-bold white-space-nowrap"> {{ 'Options' | translate }}</span>
            </span>
          </ng-template>
          <div class="p-1 ml-2">
            <div class="flex flex-column gap-3">
              <div class="flex align-items-center">
                <p-radioButton
                  [ngModelOptions]="{ standalone: true }"
                  [inputId]="'all'"
                  [value]="'all'"
                  [(ngModel)]="statusType"
                  (onClick)="resetCategory()" />
                <label for="all" class="ml-2">{{ 'All' | translate }}</label>
              </div>

              <div class="flex align-items-center">
                <p-radioButton
                  [ngModelOptions]="{ standalone: true }"
                  [inputId]="'status_filtered'"
                  [value]="'status_filtered'"
                  [(ngModel)]="statusType" />

                <label for="status_filtered" class="ml-2">{{
                  'Search in selected modules' | translate
                }}</label>
              </div>
            </div>
          </div>

          @if (statusType === 'status_filtered') {
            <div class="pt-2 mt-2">
              <div class="grip col-12 max-w-full">
                <div class="grid formgrid p-fluid">
                  <div class="field col-12">
                    <div
                      class="inline-flex justify-content-center align-items-center cursor-pointer">
                      <p-inputSwitch formControlName="is_ads" [inputId]="'is_ads'" />
                      <label
                        for="is_ads"
                        class="ml-3 mb-1 cursor-pointer  {{
                          form.value['is_ads'] ? 'text-white' : 'text-red-400'
                        }}">
                        {{ 'Ads' | translate }}</label
                      >
                    </div>

                    <div
                      class="inline-flex justify-content-center align-items-center cursor-pointer ml-3">
                      <p-inputSwitch formControlName="is_businesses" [inputId]="'is_businesses'" />
                      <label
                        for="is_businesses"
                        class="ml-3 mb-1 cursor-pointer  {{
                          form.value['is_businesses'] ? 'text-white' : 'text-red-400'
                        }}">
                        {{ 'Businesses' | translate }}</label
                      >
                    </div>

                    <div
                      class="inline-flex justify-content-center align-items-center cursor-pointer ml-3">
                      <p-inputSwitch formControlName="is_apps" [inputId]="'is_apps'" />
                      <label
                        for="is_apps"
                        class="ml-3 mb-1 cursor-pointer  {{
                          form.value['is_apps'] ? 'text-white' : 'text-red-400'
                        }}">
                        {{ 'Applications' | translate }}</label
                      >
                    </div>

                    <div
                      class="inline-flex justify-content-center align-items-center cursor-pointer ml-3">
                      <p-inputSwitch formControlName="is_members" [inputId]="'is_members'" />
                      <label
                        for="is_members"
                        class="ml-3 mb-1 cursor-pointer  {{
                          form.value['is_members'] ? 'text-white' : 'text-red-400'
                        }}">
                        {{ 'Members' | translate }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</offconon-shared-ui-search-box>
