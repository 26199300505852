import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EmployeeRuleByCoWorkerRule } from '@offconon/shared/features/generated-api';

import {
  WORKROOM_FEATURE_RBAC_RULE_FEATURE_KEY,
  WorkroomFeatureRbacRuleState,
  workroomFeatureRbacRuleAdapter,
} from './workroom-feature-rbac-rule.reducer';

// Lookup the 'WorkroomFeatureRbacRule' feature state managed by NgRx
export const selectWorkroomFeatureRbacRuleState =
  createFeatureSelector<WorkroomFeatureRbacRuleState>(WORKROOM_FEATURE_RBAC_RULE_FEATURE_KEY);

const { selectAll, selectEntities } = workroomFeatureRbacRuleAdapter.getSelectors();

export const selectWorkroomFeatureRbacRuleError = createSelector(
  selectWorkroomFeatureRbacRuleState,
  (state: WorkroomFeatureRbacRuleState) => state.error,
);

export const selectAllWorkroomFeatureRbacRule = createSelector(
  selectWorkroomFeatureRbacRuleState,
  (state: WorkroomFeatureRbacRuleState) => selectAll(state),
);

export const selectWorkroomFeatureRbacRuleEntities = createSelector(
  selectWorkroomFeatureRbacRuleState,
  (state: WorkroomFeatureRbacRuleState) => selectEntities(state),
);

export const selectCoworkerRbacRuleList = createSelector(
  selectWorkroomFeatureRbacRuleState,
  (state: WorkroomFeatureRbacRuleState) => state.rbacRuleList,
);

export const isRbacListContainsRbacRule = (accessType: EmployeeRuleByCoWorkerRule.AccessTypeEnum) =>
  createSelector(selectWorkroomFeatureRbacRuleState, (state: WorkroomFeatureRbacRuleState) =>
    state.rbacRuleList.find((rbacRule) => rbacRule.access_type === accessType) ? true : false,
  );
export const selectRbacListRbacRuleByAccessType = (
  accessType: EmployeeRuleByCoWorkerRule.AccessTypeEnum,
) =>
  createSelector(selectWorkroomFeatureRbacRuleState, (state: WorkroomFeatureRbacRuleState) =>
    state.rbacRuleList.find((rbacRule) => rbacRule.access_type === accessType),
  );
export const selectRbacListRbacRuleByRuleType = (
  ruleType: EmployeeRuleByCoWorkerRule.RuleTypeEnum,
) =>
  createSelector(selectWorkroomFeatureRbacRuleState, (state: WorkroomFeatureRbacRuleState) =>
    state.rbacRuleList.filter((rbacRule) => rbacRule.rule_type === ruleType),
  );
