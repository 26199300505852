import { Location, NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InplaceModule } from 'primeng/inplace';
import { SidebarModule, Sidebar } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';

import { ThemeService } from '@offconon/shared/ui/theme-switch';

import { NavElementComponent } from '../nav-element/nav-element.component';

@Component({
  selector: 'offconon-left-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    MatIcon,
    TooltipModule,
    TranslateModule,

    InplaceModule,
    NavElementComponent,
    NgClass,
  ],
  templateUrl: './layout-left-sidebar.component.html',
  styleUrl: './layout-left-sidebar.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LayoutLeftSidebarComponent implements OnInit, OnChanges {
  @Input() sideBarVisible = false;
  @Input() sideNavData: any;
  @Output() sideBarVisibleChange = new EventEmitter<boolean>();
  @ViewChild('searchInplace') private searchInplace: any;
  @ViewChild('appSidebar') private appSidebar: Sidebar;

  filteredNavData: any;
  isMobileView = true;
  public activeTab = 0;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private location: Location,
  ) {
    inject(ThemeService).mobileView.subscribe({
      next: (isMobileView: boolean) => {
        this.isMobileView = isMobileView;
      },
    });
  }

  ngOnInit(): void {
    this.getActiveLink();
  }

  ngOnChanges(): void {
    this.getActiveLink();

    this.filteredNavData = this.sideNavData;
  }
  resetFilter() {
    this.filteredNavData = this.sideNavData;
    this.searchInplace.deactivate();
  }
  clickErpImg() {
    this.router.navigate(['/']);
  }
  openSearchInput() {
    this.searchInplace.activate();
  }

  closeSearchMenuAction() {
    this.searchInplace.deactivate();
  }
  getActiveLink() {
    if (this.sideNavData !== undefined) {
      this.findActiveUrl();
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.findActiveUrl();
        }
      });
    }
  }

  findActiveUrl() {
    let url = this.location.path().split('/')[2];
    this.sideNavData.forEach((element: any, index: number) => {
      element.elements.find((item: any) => {
        if (item.activeUrl === url) {
          this.activeTab = index;
        }
      });
    });
  }

  onSearchChange(searchValue: any): void {
    if (searchValue.value === '') {
      this.filteredNavData = this.sideNavData;
      return;
    }
    this.filteredNavData = [
      {
        name: this.translate.instant('Search results'),
        elements: [],
        menuIcon: 'search',
        visible: true,
      },
    ];
    this.sideNavData.forEach((item: any) => {
      const filtered = item.elements.filter((element: any) => {
        return element.firstLine.toLowerCase().includes(searchValue.value.toLowerCase());
      });
      this.filteredNavData[0].elements.push(...filtered);
      this.activeTab = 0;
    });
  }
  resetSideBar() {
    this.sideBarVisibleChange.emit(false);
    this.sideBarVisible = false;
  }
  public hideSideBar(): void {
    this.appSidebar.hide();
    this.sideBarVisibleChange.emit(false);
    this.sideBarVisible = false;
  }
}
