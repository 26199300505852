<a
  tooltipPosition="bottom"
  class="flex p-3 lg:px-3 lg:py-2 align-items-center cursor-pointer"
  [appendTo]="'body'"
  pTooltip="{{ 'Languages' | translate }}"
  (click)="langChangeSidebar = true">
  <img
    src="/assets/images/blocks/flag/flag_placeholder.png"
    style="width: 28px"
    [class]="'mr-2 flag flag-s-' + usedLang.toLowerCase()" />
</a>

<p-sidebar
  #langChangeSidebarRef
  [fullScreen]="true"
  [appendTo]="'body'"
  [modal]="false"
  [closeOnEscape]="true"
  [showCloseIcon]="false"
  [(visible)]="langChangeSidebar">
  <div class="flex flex-column lg:flex-row align-items-center justify-content-between mb-3">
    <div class="text-center lg:text-left lg:pr-8">
      <div class="text-primary font-light mt-3 mb-1 text-xl">
        {{ 'Languages' | translate }}
      </div>
      <div class="text-700 text-sm font-italic mb-3 mt-2">
        {{
          'We are available in more than 200 countries / regions. We are here for you, wherever you are.'
            | translate
        }}<sup>*</sup>
      </div>
    </div>
    <div class="mt-2 lg:mt-0 flex flex-wrap lg:flex-nowrap">
      <button
        pButton
        icon="pi pi-times"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded p-button-danger font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        label="{{ 'Close' | translate }}"
        (click)="hideLangChangeSidebar()"></button>
    </div>
  </div>
  <p-divider class="w-full" />

  <div class="flex justify-content-between flex-wrap">
    @for (item of langsrc; track item) {
      <div class="flex align-items-center justify-content-center m-4 md:m-5 lg:m-6">
        <div class="mb-1 mt-1 flex align-items-center justify-content-between cursor-pointer">
          <div class="flex align-items-center" (click)="onLangChange(item)">
            <img
              src="/assets/images/blocks/flag/flag_placeholder.png"
              style="width: 45px"
              [class]="'mr-2 flag flag-s-' + item?.toLowerCase()" />

            <div class="ml-1">
              @switch (item) {
                @case ('zh-cn') {
                  <span
                    class="block mb-1 text-sm  white-space-nowrap {{
                      usedLang === item ? 'text-primary font-bold' : ''
                    }}">
                    {{ languageHelperService.languageEnums['zh_cn_language_name'] }}
                    @if (usedLang !== item) {
                      <p class="m-0 text-xs font-italic font-light">
                        {{ languageHelperService.languageEnums['zh_cn_native_name'] }}
                      </p>
                    }
                  </span>
                }
                @case ('zh-tw') {
                  <span
                    class="block mb-1 text-sm  white-space-nowrap {{
                      usedLang === item ? 'text-primary font-bold' : ''
                    }}">
                    {{ languageHelperService.languageEnums['zh_tw_language_name'] }}
                    @if (usedLang !== item) {
                      <p class="m-0 text-xs font-italic font-light">
                        {{ languageHelperService.languageEnums['zh_tw_native_name'] }}
                      </p>
                    }
                  </span>
                }
                @default {
                  <span
                    class="block mb-1 text-sm  white-space-nowrap {{
                      usedLang === item ? 'text-primary font-bold' : ''
                    }}">
                    {{ languageHelperService.languageEnums[item + '_language_name'] }}
                    @if (usedLang !== item) {
                      <p class="m-0 text-xs font-italic font-light">
                        {{ languageHelperService.languageEnums[item + '_native_name'] }}
                      </p>
                    }
                  </span>
                }
              }
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  <p-divider class="w-full" />
  <div class="p-3 text-500 text-xs font-italic">
    <sup>*</sup>{{ 'We use integrated artificial intelligence for translation.' | translate }}
  </div>
</p-sidebar>
