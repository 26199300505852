import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, catchError, of } from 'rxjs';

import { ErpSideService } from '@offconon/shared/features/generated-api';

import * as WorkroomFeatureRbacRuleActions from './workroom-feature-rbac-rule.actions';

@Injectable()
export class WorkroomFeatureRbacRuleEffects {
  private actions$ = inject(Actions);
  private erpSideService = inject(ErpSideService);

  fetchRbacRuleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkroomFeatureRbacRuleActions.fetchCoworkerRbacRuleList),
      switchMap(() =>
        this.erpSideService
          .erpSideCoWorkerRbacList()
          .pipe(
            switchMap((rbacRuleList) =>
              of(WorkroomFeatureRbacRuleActions.fetchCoworkerRbacRuleListSuccess({ rbacRuleList })),
            ),
          ),
      ),
      catchError((error) =>
        of(WorkroomFeatureRbacRuleActions.fetchCoworkerRbacRuleListFailure({ error })),
      ),
    ),
  );
}
