import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { SideBarService } from './services/side-bar.service';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { PlatformService } from '@offconon/shared/utils/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedUiThemeSwitchComponent, ThemeService } from '@offconon/shared/ui/theme-switch';

import { ToastModule } from 'primeng/toast';
import { LayoutComponent } from '@offconon/shared/features/layout';
import { LayoutSearchComponent } from '@offconon/shared/features/layout/layout-search';
import { LanguageChangeOnlyFlagComponent } from '@offconon/shared/features/language-change';

@Component({
  selector: 'offconon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    LayoutComponent,
    LayoutSearchComponent,
    NgIf,
    SharedUiThemeSwitchComponent,
    LanguageChangeOnlyFlagComponent,
    ToastModule,
  ],
})
export class AppComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private themeService = inject(ThemeService);
  private platformService = inject(PlatformService);

  title = 'admin';
  isMobileView = false;
  sideNav: any;

  constructor(private sideBarService: SideBarService) {
    this.themeService.mobileView
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isMobileView) => {
        this.isMobileView = isMobileView;
      });

    this.platformService.setPlatform('admin');
  }

  public ngOnInit(): void {
    this.setSideNav();
  }
  setSideNav() {
    this.sideNav = this.sideBarService.getMenus();
  }
}
