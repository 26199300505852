import { trigger, state, style, transition, animate } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { variables } from 'libs/shared/utils/variables/variables';
import { SidebarModule } from 'primeng/sidebar';
import { StyleClassModule } from 'primeng/styleclass';

import { FcmComponent } from '@offconon/shared/features/fcm';
import { LayoutLeftSidebarComponent } from '@offconon/shared/features/layout/layout-left-sidebar';
import { LoadingLoaderTriangleComponent } from '@offconon/shared/ui/loading-loader-cube';
import { ThemeService } from '@offconon/shared/ui/theme-switch';
import { LayoutService } from '@offconon/shared/utils/services';
import { fetchCoworkerRbacRuleList } from '@offconon/workroom/features/rbac-module';

@Component({
  selector: 'offconon-layout',
  standalone: true,
  imports: [
    LayoutLeftSidebarComponent,
    RouterModule,
    SidebarModule,
    StyleClassModule,
    LoadingLoaderTriangleComponent,
    FcmComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  animations: [
    trigger('slideInAndOutAnimation', [
      state('false', style({ top: '0' })),
      state('true', style({ top: '-70px' })),

      transition('false => true', animate('0.2s ease-in')),
      transition('true => false', animate('0.2s ease-in')),
    ]),
  ],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @Input() sideNavData: any = [];
  @Input() isLoggedIn = true;
  private store = inject(Store);

  sideBarVisible: any = false;
  loading = false;
  @HostBinding('class') public themeClass = '';
  @HostListener('window:resize', ['$event'])
  private getScreenSize() {
    this.isMobileView = window.innerWidth < 1025;
    this.themeService.updatedMobileViewSelection(this.isMobileView);
  }
  @HostListener('window:scroll', ['$event'])
  private onScroll() {
    const scrollDistance = window.pageYOffset;
    if (scrollDistance < this.previousScrollDistance) {
      this.isNavbarHidden = false;
    } else {
      this.isNavbarHidden = window.pageYOffset >= 10 ? true : false;
    }
    this.previousScrollDistance = scrollDistance;
  }
  themeService = inject(ThemeService);
  translate = inject(TranslateService);
  layoutService = inject(LayoutService);
  public isMobileView = false;
  public sidebarHidden = false;
  public isNavbarHidden = false;
  public previousScrollDistance = 0;

  constructor(
    private http: HttpClient,
    @Optional()
    @Inject(REQUEST)
    private request: any,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  public ngAfterViewInit(): void {
    this.getScreenSize();
    this.hideSideBar();
    this.themeService.updatedMobileViewSelection(this.isMobileView);
    this.getContentWidth();
  }

  ngOnInit() {
    this.http.get('assets/locales.json').subscribe((data: any) => {
      this.translate.addLangs(data.locales);
    });

    this.translate.setDefaultLang(variables.defaultLanguage);

    this.translate.onLangChange.subscribe(() => {
      this.themeService.setRtlView();
    });
    this.themeService.setRtlView();

    this.layoutService.layoutLoading$.subscribe({
      next: (loading) => {
        this.loading = loading;
      },
    });
    this.store.dispatch(fetchCoworkerRbacRuleList());
  }

  getContentWidth() {
    this.themeService.updatedContentWidth(window.innerWidth);
  }
  public hideSideBar(): void {
    this.sideBarVisible = false;
  }
  public getLang(): string {
    let lang: any;
    if (isPlatformBrowser(this.platformId)) {
      lang = this.translate.getBrowserLang();
    } else {
      lang = (this.request.headers['accept-language'] || '').substring(0, 2);
    }
    return lang;
  }
}
