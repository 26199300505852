import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';

import {
  AbstractFilterComponent,
  SharedUiSearchBoxComponent,
} from '@offconon/shared/ui/search-box';
import { ThemeService } from '@offconon/shared/ui/theme-switch';

@Component({
  selector: 'offconon-general-search-module',
  standalone: true,
  imports: [
    CommonModule,
    SharedUiSearchBoxComponent,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    RadioButtonModule,
    ButtonModule,
    TranslateModule,
    DividerModule,
    MatIcon,
    AccordionModule,
    InputSwitchModule,
  ],
  styles: [
    `
      ::-webkit-input-placeholder {
        color: #ffffff;
      }
    `,
  ],
  templateUrl: './general-search-module.component.html',
})
export class GeneralSearchModuleComponent extends AbstractFilterComponent<any> implements OnInit {
  @Input() parameters: any;
  private themeService = inject(ThemeService);
  isMobileView = false;

  recent_search_parameters: any = {};

  statusType: 'all' | 'status_filtered' = 'all';
  categoryType: 'physical_product_template' | 'plant' | 'animal' = 'physical_product_template';

  constructor(
    public override store$: Store,
    public override activatedRoute: ActivatedRoute,
  ) {
    super(store$, activatedRoute);
  }

  ngOnInit() {
    //todo from store
    this.recent_search_parameters = {
      keywords: [
        {
          label: 'How to be billionaire',
          id: 1,
        },
        {
          label: 'hello',
          id: 2,
        },
        {
          label: 'sell something',
          id: 3,
        },
        {
          label: 'Habibi come to Dubai',
          id: 4,
        },
      ],
    };

    this.themeService.mobileView.subscribe((isMobileView) => {
      this.isMobileView = isMobileView;
    });

    this.initForm();

    this.subscribeToFormValueChanges();
  }

  initForm() {
    this.form = this.fb.group({
      translations: [''],
      is_ads: [true],
      is_businesses: [true],
      is_apps: [true],
      is_members: [true],
    });
  }

  recentkeyword(data: any) {
    this.form.patchValue({
      translations: data,
    });
  }

  setSearchJson(formData: any) {
    this.searchJson = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const elem = formData[key];
        switch (key) {
          case 'translations':
            this.searchJson[key] = elem;
            break;

          case 'page':
          case 'page_size':
            break;
          default:
            this.searchJson[key] = true;
            break;
        }
      }
    }
  }

  resetCategory() {
    this.form.patchValue({
      category_template_id: undefined,
    });
  }
}
