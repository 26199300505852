<p-sidebar
  #appSidebar
  [blockScroll]="true"
  [showCloseIcon]="false"
  [styleClass]="'left-sidebar-container md:w-30rem'"
  [baseZIndex]="10000"
  [fullScreen]="isMobileView"
  [(visible)]="sideBarVisible"
  (onHide)="resetSideBar()">
  <ng-template pTemplate="header">
    <div class="flex h-full w-full">
      <div class="w-4rem bg-dark-focus flex-shrink-0 select-none hide-scrollbar flex-column">
        <div
          class="flex align-items-center justify-content-center flex-shrink-0"
          style="height: 60px">
          <a href="/"><img src="/assets/images/offconon.png" alt="OffConOn" height="30" /></a>
        </div>
        <div class="mt-3 icon-sidebar overflow-y-auto hide-scrollbar">
          <ul class="list-none py-3 pl-2 pr-0 m-0">
            @for (item of filteredNavData; track $index) {
              @if (item.visible) {
                <li class="mb-2">
                  <a
                    class="flex align-items-center cursor-pointer py-3 pl-0 pr-2 justify-content-center hover:bg-indigo-600 text-indigo-100 hover:text-indigo-50 transition-duration-150 transition-colors"
                    style="border-top-left-radius: 30px; border-bottom-left-radius: 30px"
                    [ngClass]="{ 'bg-light-focus': activeTab === $index }"
                    (click)="activeTab = $index">
                    <mat-icon
                      tooltipPosition="right"
                      [appendTo]="'body'"
                      pTooltip=" {{ item.name | translate }}"
                      >{{ item.menuIcon }}</mat-icon
                    >
                  </a>
                </li>
              }
            }
          </ul>
        </div>

        <hr class="mb-3 mx-2 border-top-1 border-none border-indigo-300" />
        <span
          class="m-3 flex align-items-center cursor-pointer p-2 justify-content-center hover:bg-indigo-600 border-round text-300 hover:text-0 transition-duration-150 transition-colors"
          (click)="clickErpImg(); hideSideBar()">
          <img
            src="/assets/images/msg/only_erp_premium.svg"
            style="width: 24px; height: 24px"
            alt="" />
        </span>
      </div>
      <div class="flex-1 flex flex-column sidebar-back-color bg-indigo-transition-left">
        <p-inplace #searchInplace [closable]="false">
          <ng-template pTemplate="display">
            <div class="border-bottom-1 border-400">
              <span
                class="text-blue-500 flex h-2rem justify-content-center font-light text-sm font-italic">
                <i class="pi pi-search mr-1 text-blue-500"></i>
                {{ 'Search in the menu' | translate }}
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="flex">
              <input
                class="w-full"
                autofocus
                id="float-input"
                type="text"
                pInputText
                (input)="onSearchChange($event.target)" />
              <a
                pButton
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-sm flex align-items-center justify-content-center"
                (click)="closeSearchMenuAction()"></a>
            </div>
          </ng-template>
        </p-inplace>
        <div class="justify-content-end mb-3 flex lg:hidden">
          <button
            icon="pi pi-times"
            class="cursor-pointer text-white appearance-none bg-transparent border-none inline-flex justify-content-center align-items-center border-circle hover:bg-indigo-600 transition-duration-150 transition-colors"
            style="width: 2rem; height: 2rem"
            pStyleClass="#app-sidebar"
            leaveToClass="hidden"
            leaveActiveClass="fadeoutleft"
            (click)="hideSideBar()">
            <i class="pi pi-times text-xl text-indigo-100"></i>
          </button>
        </div>
        @if (filteredNavData) {
          <div class="border-round flex-auto pl-2 pr-2">
            @for (item of filteredNavData; track $index) {
              <div [ngClass]="{ hidden: activeTab !== $index }">
                @if (item.visible) {
                  <div
                    class="font-light capitalize text-lg text-white {{
                      item?.subname ? 'mb-1' : 'mb-5'
                    }} text-center">
                    {{ item.name | translate }}
                  </div>
                  @if (item?.subname) {
                    <div class="font-light font-italic text-sm text-teal-500 mb-5 text-center">
                      {{ item.subname | translate }}
                    </div>
                  }
                  <ul
                    class="list-none p-0 m-0 text-white overflow-y-auto hide-scrollbar offconon-nav-element-container">
                    @for (element of item.elements; track element) {
                      <offconon-nav-element
                        [data]="element"
                        (linkClickedEvent)="hideSideBar(); resetFilter()" />
                    }
                  </ul>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  </ng-template>
</p-sidebar>
